<template>
  <div>
    <v-row class="bbr-program-plan--form-deatils">
      <v-col cols="12" sm="3">
        <div class="mb-6 d-flex">
          <v-btn
            v-if="!onEdit && planIcons.length"
            class="ml-3"
            color="primary"
            :loading="loading"
            @click="startEditing"
            text
          >
            <v-icon class="mr-3"> {{ icons.edit }} </v-icon>
            Edit Icons
          </v-btn>

          <v-btn v-if="onEdit" class="ml-3" @click="cancelEdit" depressed>
            Finish Editing
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="!onEdit" class="bbr-program-plan--form-deatils">
      <div class="mb-10 text-right"></div>
      <v-col cols="12" class="d-flex align-center ">
        <v-combobox
          v-model="newIcon"
          item-text="name"
          item-value="id"
          :items="selectableIcons"
          :error-messages="errors"
          :hide-details="!errors.length"
          class="mr-5"
        >
        </v-combobox>
        <v-btn
          color="primary"
          :loading="loading"
          @click="submitNewIcon"
          depressed
        >
          Add
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="bbr-program-plan--form-deatils">
      <v-col cols="12" class="d-flex align-center">
        <draggable
          tag="div"
          :list="onEdit ? copyPlansIcons : planIcons"
          :animation="180"
          :force-fallback="true"
          fallback-class="chosen"
          ghost-class="moving-instruction"
          group="exercise-instructions"
          handle=".grab-instruction"
          class="full-width"
        >
          <template
            v-for="(editItem, index) in onEdit ? copyPlansIcons : planIcons"
          >
            <div :key="`${index}_${editItem.id}`" class="full-width ">
              <v-divider v-if="index === 0" />
              <div class=" py-4 d-flex align-center">
                <div class="full-width">
                  {{ editItem.name }}
                </div>
                <div class="d-flex items-center">
                  <v-btn v-if="onEdit" @click="removeItem(index)" icon>
                    <v-icon class="red--text">
                      {{ icons.remove }}
                    </v-icon>
                  </v-btn>
                  <v-btn v-if="onEdit" class="grab-instruction" icon>
                    <v-icon> {{ icons.drag }} </v-icon>
                  </v-btn>
                </div>
              </div>
              <v-divider />
            </div>
          </template>
        </draggable>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiPlus, mdiPencil, mdiClose, mdiDragVertical } from '@mdi/js'
import draggable from 'vuedraggable'
import { clone, map } from 'lodash'
import { mapActions } from 'vuex'

export default {
  name: 'PlanDetailsIconsList',
  components: {
    draggable,
  },
  props: {
    value: Array,
  },
  emits: ['input'],
  data() {
    return {
      icons: {
        add: mdiPlus,
        edit: mdiPencil,
        remove: mdiClose,
        drag: mdiDragVertical,
      },
      copyPlansIcons: [],
      planIcons: [],
      onEdit: false,
      addNew: false,
      newIcon: { id: '', name: '' },
      errors: [],
      iconsRefList: [],
      selectableIcons: [],
      loading: false,
    }
  },
  methods: {
    ...mapActions({
      getPlanIconsList: 'plans/getPlanIconsList',
    }),
    removeItem(index) {
      this.copyPlansIcons.splice(index, 1)
      this.generateSelectableList()
    },
    submitNewIcon() {
      const inList = this.selectableIcons.find(
        (si) => si.id === this.newIcon.id
      )
      this.errors = ['']
      this.planIcons.push(inList)
      this.addNew = true
      this.newIcon = { id: '', name: '' }
      this.$emit('input', this.planIcons)
      this.generateSelectableList()
    },
    startEditing() {
      this.onEdit = true
      this.addNew = false
      this.copyPlansIcons = this.planIcons
    },
    cancelEdit() {
      this.onEdit = false
      this.planIcons = this.copyPlansIcons
      this.$emit('input', this.planIcons)
      this.copyPlansIcons = []
    },

    generateSelectableList() {
      this.selectableIcons = map(this.iconsRefList, (n) => {
        return {
          disabled: this.planIcons.find((m) => {
            return n.id === m.id
          }),
          ...n,
        }
      })
    },
  },
  created() {
    this.planIcons = this.value || []
    return this.getPlanIconsList().then(({ data }) => {
      this.iconsRefList = clone(data.data.icons)
      this.generateSelectableList()
    })
  },

  watch: {
    value(values) {
      this.planIcons = clone(values)
      this.generateSelectableList()
    },

    loading(saving) {
      if (saving) {
        this.onEdit = false
        this.addNew = false
        this.copyPlansIcons = clone(this.planIcons)
      }
    },
  },
}
</script>
