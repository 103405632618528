<template>
  <div class="bbr-program-plans--programs">
    <v-row>
      <v-col cols="12" sm="12" md="10" lg="8" xl="6">
        <div class="d-flex justify-space-between align-center mb-4">
          <h2 class="mr-auto">Plan Programs</h2>
          <v-btn
            class="bg-primary-gradient primary next-button px-12"
            :loading="loading"
            @click="saveAndClose"
            depressed
            text
          >
            Save Plan & Close
            <v-icon> {{ icons.check }} </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="10" lg="8" xl="6">
        <v-alert type="info" prominent text outlined>
          Programs listed below are for viewing only. Adding or Deleting of
          program/s on the current plan/package will be done in the
          corresponding Programs and Challenges pages.
        </v-alert>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="10" lg="8" xl="6">
        <v-card outlined>
          <template
            class="clickable"
            v-for="(program, programIndex) in planPrograms"
          >
            <v-card-text
              class="clickable"
              @click="view(program)"
              :key="programIndex"
            >
              <div class="d-flex justify-space-between align-center">
                <div>
                  <div class="subtitle-1">{{ program.name }}</div>
                  <div v-if="program.description" class="caption text--primary">
                    {{ program.description }}
                  </div>
                </div>

                <div>
                  <v-chip
                    class="ma-2"
                    :color="getColor(program.completed_at)"
                    small
                  >
                    {{ program.completed_at ? 'Active' : 'Draft' }} Program
                  </v-chip>
                </div>
              </div>
            </v-card-text>

            <v-divider :key="`divider-${programIndex}`" />
          </template>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiCheck } from '@mdi/js'
import { mapGetters } from 'vuex'

export default {
  name: 'PlanPrograms',

  data() {
    return {
      loading: false,
      icons: {
        check: mdiCheck,
      },
    }
  },

  computed: {
    ...mapGetters({
      planPrograms: 'plans/getSelectedPlanPrograms',
    }),
  },

  methods: {
    getColor(completed) {
      return completed ? 'primary' : 'warning'
    },

    view(program) {
      this.$router.push({
        name: program.type === 1 ? 'program.details' : 'challenge.details',
        params: { id: program.id },
      })
    },

    saveAndClose() {
      this.loading = true

      setTimeout(() => {
        this.loading = false

        this.$router.push({ name: 'active.plans' })
      }, 800)
    },
  },
}
</script>
